
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { getPanel } from "@/api/encounter.api";
import {
  getFilters,
  postAction,
  searchClaims,
  updateBillingIndicator,
} from "@/api/claim.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Claim } from "@/modules/claims/claim.model";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "@/modules/common/useFilters";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import { getList } from "@/api/dropdown.api";
import { getClearinghouseStatuses } from "@/api/dictionaries.api";
import { getInsurancePaymentDistribution } from "@/api/insurancePaymentDistribution.api";
import NoteComponent from "@/modules/note/NotesComponent.vue";
import { ClaimStatusList } from "@/store/enums/StoreEnums";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  emptyListError?: string;
  selectAll: boolean;
  items: [];
  selectedItems: Claim[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    claimsStatusCodes: StatusCode[];
    encounterTypes: StatusCode[];
    patientPayers: { payerId: string; name: string; payerName: string }[];
    facilities: { id: string }[];
    actualClaimsStatusCodes?: StatusCode[];
    actualEncounterTypes?: StatusCode[];
    actualPatientPayers?: { payerId: string; payerName: string }[];
    actualFacilities?: { id: string }[];
    actualBillingIndicators: { value: string }[];
    actualClearinghouseStatuses: { code: string }[];
    claimActions: [];
    billingIndicators: { value: string }[];
    clearinghouseStatuses: { code: string; description: string }[];
  };
  filter: {
    allSelected: boolean;
    startDate: Date | string | null;
    endDate: Date | string | null;
    currentResponsibleParty: [];
    status: [];
    facility: [];
    billingIndicator: [];
    clearinghouseStatus: [];
    statuses: number[];
    globalSearch: string | null;
  };
  modalClaimId;
  modalEncounterId;
  modalPatientId;
  modal;
}
export default defineComponent({
  name: "ClaimManagement",
  components: { Multiselect, PaginationUi, NoteComponent },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const {
      orderList,
      putFiltersToUrl,
      getFiltersFromUrl,
    } = useFilters();

    orderList.value = [
      {
        name: " ",
        key: " ",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Claim ID",
        key: "claimId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Encounter ID",
        key: "encounter.encounterId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Patient",
        key: "patient",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "DOS",
        isFilter: true,
        key: "serviceDate",
        formType: "isDate",
        keyword: null,
      },
      {
        name: "Facility",
        key: "encounter.facility.id",
        isFilter: true,
        keyword: null,
        formType: "facility",
        orderProperty: "encounter.facility.name",
      },
      {
        name: "Responsible Party",
        key: "payer.payerId",
        isFilter: true,
        keyword: null,
        formType: "patientPayer",
        orderProperty: "payer.name",
      },
      {
        name: "Total Charges",
        key: "totalCharges",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Claim Payment",
        key: "totalInsurancePayments",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Age",
        key: "age",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
        formType: "number",
      },
      {
        name: "Assign To",
        key: "age",
        isFilter: false,
        keyword: null,
        formType: "number",
      },
      {
        name: "Review Date",
        key: "reviewDate",
        isFilter: true,
        formType: "isDate",
        keyword: null,
        orderProperty: "reviewDate",
      },
      {
        name: "Billing Indicator",
        key: "billingIndicator",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Claim Status",
        key: "status",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Clearinghouse Status",
        key: "waystarStatus",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Notes",
        key: "note",
        isFilter: false,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      selectAll: false,
      items: [],
      keyword: "",
      selectedItems: [],
      orderBy: "default",
      IsAdvancedOrderBy: true,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        claimsStatusCodes: [],
        encounterTypes: [],
        claimActions: [],
        patientPayers: [],
        facilities: [],
        billingIndicators: [],
        clearinghouseStatuses: [],
        actualBillingIndicators: [],
        actualClearinghouseStatuses: [],
      },
      filter: {
        allSelected: false,
        startDate: null,
        endDate: null,
        currentResponsibleParty: [],
        status: [],
        facility: [],
        billingIndicator: [],
        clearinghouseStatus: [],
        statuses: [
          ClaimStatusList.OnHold, 
          ClaimStatusList.PendingSubmission, 
          ClaimStatusList.SubmissionFailed, 
          ClaimStatusList.Submitted, 
          ClaimStatusList.VoidFailed,
        ],
        globalSearch: null,
      },
      modalClaimId: null,
      modalEncounterId: null,
      modalPatientId: null,
      modal: false,
    });

    async function cleanFilter() {
      data.filter = {
        allSelected: false,
        startDate: null,
        endDate: null,
        currentResponsibleParty: [],
        status: [],
        facility: [],
        billingIndicator: [],
        clearinghouseStatus: [],
        statuses: [
          ClaimStatusList.OnHold, 
          ClaimStatusList.PendingSubmission, 
          ClaimStatusList.SubmissionFailed, 
          ClaimStatusList.Submitted, 
          ClaimStatusList.VoidFailed,
        ],
        globalSearch: null,
      };
      data.selectedItems = [];
      data.selectAll = false;
      data.keyword = "";
      data.orderBy = "default";
      data.isDecr = false;
      data.IsAdvancedOrderBy = true;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Claim Management");
      getFiltersFromUrl();
      //init filters list
      data.dict.claimActions = store.getters.allClaimActions.filter((item)=>{ return item.code != 3 && item.code != 4});
      data.dict.claimsStatusCodes = store.getters.allClaimStatuses.sort(
        (a, b) => a.description.localeCompare(b.description)
      );
      if (route.query && route.query.status) {
        if (route.query.status === ClaimStatusList.PendingSubmission.toString()) {
          data.filter.statuses = [ClaimStatusList.PendingSubmission, ClaimStatusList.SubmissionFailed] as any;
        }else{
          data.filter.statuses = [route.query.status] as any;
        }
      }
      data.dict.encounterTypes = store.getters.allEncounterTypes;
      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.dict.patientPayers = await searchDictInsuranceCompanys({
        search: "",
      });
      data.dict.billingIndicators = await getList({
        type: "Billing Indicator",
      });
      data.dict.billingIndicators = data.dict.billingIndicators.sort((a, b) =>
        a.value.localeCompare(b.value)
      );
      data.dict.clearinghouseStatuses = await getClearinghouseStatuses();
      //get all items
      await getAll(true);
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key || data.orderBy == header.orderProperty) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      if (header.orderProperty) {
        data.orderBy = header.orderProperty;
      } else {
        data.orderBy = header.key;
      }

      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key, orderProperty) {
      if (data.orderBy == key || data.orderBy == orderProperty) return true;
      return false;
    }

    async function search() {
      await nextTick();
      data.selectedItems = [];
      data.selectAll = false;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll(doUpdateFilters = false) {
      putFiltersToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        GlobalSearch: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        StartDate: data.filter.startDate,
        EndDate: data.filter.endDate,
        FacilityId: data.filter.facility,
        ResponsibleParty: data.filter.currentResponsibleParty,
        BillingIndicator: data.filter.billingIndicator,
        ClearinghouseStatus: data.filter.clearinghouseStatus,
        Statuses: data.filter.statuses,
      };

      const res = await searchClaims(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = orderList.value.filter((f) => {
          return (
            f.keyword != undefined && f.keyword !== "" && f.keyword != null
          );
        });
        if (currentFilters.length > 0) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }

      if (doUpdateFilters) {
        const filters = await getFilters();
        updateFilters(filters);
      }

      await getAllowedAmountsAndResonCodes();
    }

    function updateFilters(res) {
      //by the requet result update filters to contains only actual data
      data.dict.actualPatientPayers = data.dict.patientPayers
        .filter((c) => {
          return res.responsibleParty.find((f) => {
            return f == c.payerId;
          });
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      data.dict.actualFacilities = data.dict.facilities.filter((c) => {
        return res.facility.find((f) => {
          return f == c.id;
        });
      });

      data.dict.actualClearinghouseStatuses = data.dict.clearinghouseStatuses
        .filter((c) => {
          return res.clearinghouseStatus.find((f) => {
            return f == c.code;
          });
        })
        .sort((a, b) => a.description.localeCompare(b.description));

      data.dict.actualEncounterTypes = data.dict.encounterTypes.filter((c) => {
        return (
          res.clearinghouseStatus.find((f) => {
            return f == c.code;
          }) != undefined
        );
      });
    }

    function getStatusHeader(code) {
      if (data.dict.claimsStatusCodes) {
        const status = data.dict.claimsStatusCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    function selectItem(item) {
      router.push({ path: "claim/" + item.id + "/claimSummary" });
    }

    function newEncounter() {
      //router.push({ name: "encounter" });
    }

    async function clearSearch() {
      await search();
    }

    async function sendAction(action) {
      let actionsInfoText = {
        0: "Are you sure you want to Submit selected Claim(s)?",
        2: "If the Next Responsible Party was not selected during Insurance Payment Posting, the Claim will be transferred to the default Next Responsible Party as appears on the Encounter. Would you like to proceed?",
        3: "Are you sure you want to Void selected Claim(s)?",
      };
      const actionRes = await Swal.fire({
        title: actionsInfoText[action],
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      });
      if (!actionRes.isConfirmed) {
        return;
      }

      const res = await postAction({
        claimsId: data.selectedItems.map((item) => {
          return item.id;
        }),
        claimAction: action,
      });
      if (!res) return;
      let actionsText = {
        0: "submitted",
        2: "transfer",
        3: "voided",
      };

      let actionText = actionsText[action];
      let success = res.successList.length;
      let total = data.selectedItems.length;
      let text = `(${success} of ${total}) Claims were successfully ${actionText}.`;
      if (res.errors && res.errors.length > 0) {
        text = text + "\n";
        res.errors.forEach((error) => {
          text = text + "\n" + error;
        });
        Swal.fire("Info!", text, "info");
      } else {
        Swal.fire("Ok!", text, "success");
      }
      await search();
    }

    async function selectAllItems() {
      if (data.filter.allSelected) {
        data.selectedItems = data.items as any;
      } else {
        data.selectedItems = [];
      }
    }

    async function selectAllItemsIfManuallySelectedAll() {
      if (data.selectedItems.length == data.items.length) {
        data.filter.allSelected = true;
      } else {
        data.filter.allSelected = false;
      }
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      data.selectedItems = [];
      data.selectAll = false;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      data.selectedItems = [];
      data.selectAll = false;
      await getAll();
    }

    async function updateBillingIndicatorForClaim(claim) {
      await nextTick();
      await updateBillingIndicator(claim.id, claim.billingIndicator);
    }

    function getWaystarStatus(code) {
      const status = data.dict.clearinghouseStatuses.find((item) => {
        return item.code == code;
      });
      if (status) {
        return status.description;
      }
      return code;
    }

    async function getAllowedAmountsAndResonCodes() {
      for (let item of data.items) {
        var itemAsAny = item as any;
        for (let claimLineItem of itemAsAny.claimLineItems) {
          var distribution = await getInsurancePaymentDistributionInfo(
            claimLineItem.id,
            claimLineItem.serviceId
          );
          claimLineItem.allowed = distribution.allowed;
          if (distribution.reasonCodes?.length > 0) {
            claimLineItem.reasonAndGroupCodesDisplay = "";
            for (let reasonCode of distribution.reasonCodes) {
              if (reasonCode.groupCode) {
                claimLineItem.reasonAndGroupCodesDisplay += reasonCode.groupCode;
                if(reasonCode.reasonCode) claimLineItem.reasonAndGroupCodesDisplay += " - ";
              }
              if (reasonCode.reasonCode) {
                claimLineItem.reasonAndGroupCodesDisplay +=  reasonCode.reasonCode;
              }
              claimLineItem.reasonAndGroupCodesDisplay += ", ";

            }
            claimLineItem.reasonAndGroupCodesDisplay =
              claimLineItem.reasonAndGroupCodesDisplay.slice(0, -2);
          }
        }
        item = itemAsAny as never;
      }
    }

    async function getInsurancePaymentDistributionInfo(
      claimLineItemId,
      serviceId
    ) {
      //get  insurance payment distribution
      let request = {
        claimLineItemId: claimLineItemId,
        serviceId: serviceId,
      };
      return await getInsurancePaymentDistribution(request);
    }

    function selectNotes(claim) {
      data.modalClaimId = claim.id;
      data.modalEncounterId = claim.encounterId;
      data.modalPatientId = claim.encounter.patient.id;
      data.modal = true;
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      selectNotes,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      getStatusHeader,
      sendAction,
      newEncounter,
      selectAllItems,
      pageChanged,
      selectAllItemsIfManuallySelectedAll,
      pageSizeChanged,
      getAllowedAmountsAndResonCodes,
      getInsurancePaymentDistributionInfo,
      getWaystarStatus,
      updateBillingIndicatorForClaim,
    };
  },
});
